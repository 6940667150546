body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-grid-placeholder {
  background-color: #ddd!important;
}

.collapseIndicator {
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.collapseIndicatorToggle {
  display: inline-block;
  width: .75em;
  top: -1px;
  position: relative;
}


.dragHandle {
  font-size: 18px;
  color: #dedede;
  cursor: move;
}

.noWrap {
  white-space: nowrap;
}

.react-grid-item > .react-resizable-handle {
  background: none;
  padding: 0;
  bottom: -2px;
  right: -2px;
}

.react-grid-item > .react-resizable-handle::after {
  right: 4px;
  bottom: 4px;
  width: 8px;
  height: 8px;
  border-right: 4px solid rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 2px 2px 0;
}

@media (min-width: 576px) and (orientation: landscape) {
  .dragHandle {
    font-size: 16px;
  }
}

.grecaptcha-badge { visibility: hidden; }

a {
  color: #1a73e8
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin { 
  0% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform:rotate(0deg);
  }
}