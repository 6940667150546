.item {
    display: flex;
    align-items: center;
    list-style-type: none;
    height: 21px;
    padding-bottom: 10px;
    padding-right: 10px;
    touch-action: pan-y!important;
}

.item.complete {
    padding-left: 10px;
}

.itemHandle {
    /* If padding not wide enough, iOS will
    select checkbox when tapping drag handle */
    padding: 4px 8px 4px 8px;
    margin-right: 0px;
    touch-action: none;
}

.itemCheckbox {
    padding: 0;
    margin: 0 4px 0 1px;
    font-size: 14px;
}

.itemInput {
    flex-grow: 1;
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    /* Prevent highlight during DnD in iOS */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.item.complete > .itemInput {
    text-decoration: line-through;
}

@media (min-width: 576px) and (orientation: landscape) {
    .item {
        padding-bottom: 5px;
    }

    .itemCheckbox {
        font-size: 12px;
    }

    .itemInput {
        font-size: 12px;
    }
}

@media (min-width: 1200px) and (orientation: landscape) {
    .itemHandle {
        padding: 0 0 0 3px;
    }
}