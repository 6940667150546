.App {
  text-align: left;
}

.App > .sidebar {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 50px;
  border-bottom: 1px solid #ccc;
  background-color: #eee;
}

.App > .sidebar .logo {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 10px 10px 0 10px;
}

.App > .sidebar > .mobileSync {
  font-size: 30px;
  margin: 8px 0px 0px -15px;
  color: #aaa;
  position: absolute;
  left: 50%;
  right: 50%;
}

.App > .sidebar > .iconButton {
  display: none;
  margin: 0 0 0 30px;
  font-size: 28px;
  color: #aaa;
  cursor: pointer;
  vertical-align: top;
}

.App > .sidebar > .menu.iconButton {
  display: inline-block;
  position: absolute;
  right: 20px;
}

.App > .sidebar > .iconButton:hover {
  color: #999;
}

.App > .sidebar .importLabel {
  cursor: pointer;
}

.App > .sidebar .importInput {
  display: none;
}

.App > .layout {
  margin: 50px 20px 0px 20px;
}

.noListTip {
  font-size: 50px;
  width: 75%;
  color: #ddd;
  margin: auto;
  text-align: center;
}

@media (min-width: 576px) and (orientation: landscape) {
  .App {
    text-align: center;
  }

  .App > .sidebar {
    height: 100%;
    width: 50px;
    border-right: 1px solid #ccc;
  }

  .App > .sidebar > .logo {
    display: block;
  }

  .App > .sidebar > .mobileSync {
    display: none;
  }

  .App > .sidebar > .iconButton {
    display: block;
    margin: 15px 0 0 0;
    font-size: 30px;
  }

  .App > .sidebar > .menu.iconButton {
    display: none;
  }

  .App > .layout {
    margin: 0 0 0 50px;
  }

  .noListTip {
    font-size: 75px;
    width: 50%;
  }
}